import React from 'react';
import {
    Chip, IconButton, ListItem, ListItemText, Stack, Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SellIcon from '@mui/icons-material/Sell';
import { usePathHelper } from '@hooks/usePathHelper';
import useAxios from '../../../hooks/useAxios';

function ServiceItemBlock({ item, onDeleteCallback = () => {} }) {
    const { getApiBasePath } = usePathHelper();
    const axios = useAxios();

    const formatPrice = (price) => new Intl.NumberFormat('ru-RU', {
        style: 'currency',
        currency: 'RUB',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(price);

    const formatBonus = (bonus) => new Intl.NumberFormat('ru-RU', {
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    }).format(bonus);

    const formatBonusCopy = (bonus) => {
        const lastDigit = bonus % 10;
        const lastTwoDigits = bonus % 100;

        let suffix;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
            suffix = 'ов';
        } else if (lastDigit === 1) {
            suffix = '';
        } else if (lastDigit >= 2 && lastDigit <= 4) {
            suffix = 'а';
        } else {
            suffix = 'ов';
        }

        return `бонус${suffix}`;
    };

    const deleteHandler = (serviceId) => {
        axios.delete(
            `${getApiBasePath()}/service/${serviceId}`,
        ).then(() => {
            onDeleteCallback();
        }).catch((error) => {
            console.error('Ошибка при удалении услуги!', error);
        });
    };

    return (
        <div key={item.id}>
            <ListItem
                secondaryAction={(
                    <IconButton edge="end" aria-label="Удалить" onClick={() => deleteHandler(item.id)}>
                        <DeleteIcon fontSize="small" />
                    </IconButton>
                )}
            >
                <ListItemText
                    primary={(
                        <Typography sx={{ mb: 1 }}>
                            <SellIcon fontSize="small" color="secondary" sx={{ fontSize: 15, mr: 1 }} />
                            {item.name}
                        </Typography>
                    )}
                    secondary={(
                        <Stack spacing={1} direction="row" sx={{ mt: 1 }}>
                            <Typography>
                                <Chip
                                    label={formatPrice(item.price)}
                                    variant="outlined"
                                    size="small"
                                />
                            </Typography>
                            <Typography>
                                <Chip
                                    label={
                                        `${formatBonus(item.bonus)} ${formatBonusCopy(item.bonus)}`
                                    }
                                    variant="outlined"
                                    size="small"
                                />
                            </Typography>
                        </Stack>
                    )}
                />
            </ListItem>
        </div>
    );
}

export default ServiceItemBlock;

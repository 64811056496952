import { useLocation } from 'react-router-dom';

export const CLIENT_MODE = 'client';
export const PARTNER_MODE = 'partner';
export const EXPERT_MODE = 'expert';

export function usePathHelper() {
    const location = useLocation();

    const getMode = () => {
        if (location.pathname.startsWith('/partner')) {
            return PARTNER_MODE;
        }

        if (location.pathname.startsWith('/expert')) {
            return EXPERT_MODE;
        }

        return CLIENT_MODE;
    };

    const isPartnerMode = () => getMode() === PARTNER_MODE;

    const isExpertMode = () => getMode() === EXPERT_MODE;

    const getApiBasePath = () => {
        switch (getMode()) {
            case PARTNER_MODE:
                return '/partner';
            case EXPERT_MODE:
                return '/expert';
            default:
                return '';
        }
    };

    const getBasePath = () => {
        switch (getMode()) {
            case PARTNER_MODE:
                return '/partner';
            case EXPERT_MODE:
                return '/expert';
            default:
                return '';
        }
    };

    const getHomePagePath = () => `${getBasePath()}/`;

    return {
        getMode, getApiBasePath, getBasePath, getHomePagePath,
    };
}

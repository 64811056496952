import React, { useEffect, useState } from 'react';
import {
    TextField, Button, Typography, Container, FormControl, Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAxios from '../hooks/useAxios';
import { usePathHelper } from '../hooks/usePathHelper';
import Token from '../utils/Token';

function Register() {
    const {
        getApiBasePath, getBasePath, getMode, getHomePagePath,
    } = usePathHelper();
    const axios = useAxios();
    const navigate = useNavigate();

    useEffect(() => {
        if (Token.checkTokenExists(getMode())) {
            navigate(getHomePagePath());
        }
    }, []);

    const [userData, setUserData] = useState({
        email: '',
        password: '',
        name: '',
    });
    const [errorMessage, setErrorMessage] = useState('');
    const [isFormDisabled, setFormDisabled] = useState(false);
    const [suggestAuth, setSuggestAuth] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setFormDisabled(true);
        setSuggestAuth(false);
        setErrorMessage('');
        axios.post(
            `${getApiBasePath()}/auth/register`,
            userData,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
            .then(() => {
                setSuggestAuth(true);
            })
            .catch((error) => {
                console.error('Ошибка при регистрации!', error);
                setErrorMessage('Ошибка при регистрации. Попробуйте снова.');
                setFormDisabled(false);
            });
    };

    return (
        <Container component="main" maxWidth="xs" sx={{ mt: 5 }}>
            <Typography component="h1" variant="h5">
                Регистрация
            </Typography>
            <form onSubmit={handleSubmit} className="">
                <FormControl disabled={isFormDisabled} fullWidth>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Имя"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        value={userData.name}
                        disabled={isFormDisabled}
                        onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        value={userData.email}
                        disabled={isFormDisabled}
                        onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Пароль"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={userData.password}
                        disabled={isFormDisabled}
                        onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        disabled={isFormDisabled}
                    >
                        Зарегистрироваться
                    </Button>
                </FormControl>
                {errorMessage && (
                    <Alert variant="outlined" severity="error" sx={{ mt: 2 }}>
                        {errorMessage}
                    </Alert>
                )}

                {suggestAuth && (
                    <Alert
                        variant="outlined"
                        severity="success"
                        sx={{ mt: 2 }}
                        action={(
                            <Button
                                href={`${getBasePath()}/login`}
                                size="small"
                                color="success"
                                variant="outlined"
                            >
                                Войти в систему
                            </Button>
                        )}
                    >
                        Успешная регистрация!
                    </Alert>
                )}
            </form>
        </Container>
    );
}

export default Register;

import React, { useEffect, useState } from 'react';
import {
    Button, Chip,
    Container, IconButton, Link,
    List,
    ListItem,
    ListItemIcon, ListItemText,
    Typography,
    ButtonGroup,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { usePathHelper } from '@hooks/usePathHelper';
import useAxios from '@hooks/useAxios';
import Box from '@mui/material/Box';
import StoreIcon from '@mui/icons-material/Store';
import DeleteIcon from '@mui/icons-material/Delete';
import QrCodeIcon from '@mui/icons-material/QrCode';

import QRCode from "qrcode.react";

function Points() {
    const navigate = useNavigate();
    const { getApiBasePath, getBasePath } = usePathHelper();
    const axios = useAxios();

    const [points, setPoints] = useState(null);

    const fetchPoints = () => {
        axios.get(
            `${getApiBasePath()}/point/`,
        ).then((response) => {
            setPoints(response.data);
        }).catch((error) => {
            console.error('Ошибка при получении локаций партнёра!', error);
        });
    };

    const deleteHandler = (pointId) => {
        axios.delete(
            `${getApiBasePath()}/point/${pointId}`,
        ).then(() => {
            fetchPoints();
        }).catch((error) => {
            console.error('Ошибка при удалении локации!', error);
        });
    };

    const downloadHandler = (itemId) => {
        const canvas = document.getElementById(`partner_point${itemId}`);
        console.log("cnv", canvas);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "parnter_point" + itemId + ".png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const renderCategories = (items) => items.map((el) => (<Chip key={el.id} size="small" label={el.name} variant="outlined" sx={{ mr: 1 }} />));

    useEffect(() => {
        fetchPoints();
    }, []);

    return (
        <Container component="main" maxWidth="md" sx={{ mt: 5 }}>
            {points && points.length < 1 && (
                <Typography variant="h5">
                    Локации отсутствуют. Добавим?
                </Typography>
            )}
            <Typography variant="body1">
                <Button
                    startIcon={<AddBusinessIcon />}
                    variant="contained"
                    size="medium"
                    onClick={() => navigate(`${getBasePath()}/point/new`)}
                    sx={{ mt: 3 }}
                >
                    Добавить локацию
                </Button>
            </Typography>
            {points && points.length > 0 && (
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h5">
                        Ваши локации:
                    </Typography>
                    <List>
                        {points.map((item) => (
                            <div key={item.id}>
                                <ListItem
                                    secondaryAction={(
                                        <ButtonGroup>
                                            <IconButton sx={{ marginRight: 1 }} edge="end" aria-label="Сгенерировать QR-код" onClick={() => { downloadHandler(item.id)}}>
                                                <QrCodeIcon fontSize="small" />
                                            </IconButton>,
                                            <IconButton edge="end" aria-label="Удалить" onClick={() => deleteHandler(item.id)}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </ButtonGroup>
                                    )}
                                    divider={item.id !== points[points.length - 1].id}
                                >
                                    <ListItemIcon>
                                        <StoreIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={(
                                            <Typography sx={{ mb: 1 }}>
                                                <Link
                                                    onClick={() => navigate(`${getBasePath()}/point/${item.id}`)}
                                                    sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                                                >
                                                    {item.name}
                                                </Link>
                                            </Typography>
                                        )}
                                        secondary={renderCategories(item.categories)}
                                    />
                                </ListItem>
                                <div style={{display: 'none'}}>
                                    <QRCode
                                        id={`partner_point${item.id}`}
                                        value={JSON.stringify({
                                            id: item.id,
                                            type: 'partner_point',
                                        })}
                                        size={400}
                                        level={"H"}
                                        includeMargin={false}
                                    />
                                </div>
                            </div>
                        ))}
                    </List>
                </Box>
            )}
        </Container>
    );
}

export default Points;

import React, { useEffect } from 'react';
import { Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthForm from '../forms/AuthForm';
import Token from '../utils/Token';
import { usePathHelper } from '../hooks/usePathHelper';

function Login() {
    const { getHomePagePath, getMode } = usePathHelper();
    const navigate = useNavigate();

    useEffect(() => {
        if (Token.checkTokenExists(getMode())) {
            navigate(getHomePagePath());
        }
    }, []);

    return (
        <Container component="main" maxWidth="xs" sx={{ mt: 5 }}>
            <Typography component="h1" variant="h5">
                Вход в приложение
            </Typography>
            <AuthForm parentId="login-page" />
        </Container>
    );
}

export default Login;

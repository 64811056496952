class Token {
    static getTokenKey(mode) {
        switch (mode) {
            case 'partner':
                return 'access_token_partner';
            case 'expert':
                return 'access_token_expert';
            default:
                return 'access_token_client';
        }
    }

    static setToken(token, mode) {
        localStorage.setItem(this.getTokenKey(mode), token);
    }

    static getToken(mode) {
        return localStorage.getItem(this.getTokenKey(mode));
    }

    static removeToken(mode) {
        localStorage.removeItem(this.getTokenKey(mode));
    }

    static checkTokenExists(mode) {
        return this.getToken(mode)?.length > 0;
    }
}

export default Token;

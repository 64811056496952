import React from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Container, createTheme } from '@mui/material';
import Header from '../components/Header';
import AuthModal from '../modals/AuthModal';
import { ClientThemeOptions, PartnerThemeOptions, ExpertThemeOptions } from './PartnerThemeOptions';
import { AuthProvider } from '../context/AuthContext';
import { usePathHelper } from '../hooks/usePathHelper';

function Layout({ mode = 'client', logoTailing = null }) {
    const { getMode, EXPERT_MODE, PARTNER_MODE } = usePathHelper();

    const getTheme = () => {
        const mode = getMode();
        switch (mode) {
            case PARTNER_MODE:
                return createTheme(PartnerThemeOptions);
            case EXPERT_MODE:
                return createTheme(ExpertThemeOptions);
            default:
                return createTheme(ClientThemeOptions);
        }
    };

    return (
        <AuthProvider>
            <ThemeProvider theme={getTheme(mode)}>
                <Container maxWidth="lg">
                    <CssBaseline />
                    <Header logoTailing={logoTailing} />
                    <Outlet/>
                    <AuthModal />
                </Container>
            </ThemeProvider>
        </AuthProvider>
    );
}

export default Layout;

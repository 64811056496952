import React, { useEffect, useState } from 'react';
import {
    Button, Container, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography, Stack, Chip
} from '@mui/material';
import { usePathHelper } from '@hooks/usePathHelper';
import useAxios from '@hooks/useAxios';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import OverlayModal from '../../../components/OverlayModal';
import {
    Map, Placemark, YMaps, ZoomControl, Polyline
} from '@pbe/react-yandex-maps';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StoreIcon from '@mui/icons-material/Store';

function ExpertRoutes() {
    const navigate = useNavigate();
    const { getApiBasePath, getBasePath } = usePathHelper();
    const axios = useAxios();

    const [routes, setRoutes] = useState(null);

    const [overlayData, setOverlayData] = useState(null);
    const [openModal, setOpenModal] = useState(false);

    const renderServices = (items) => items.map((el) => (<Chip key={el.id} size="small" label={el.name} variant="outlined" sx={{ mr: 1 }} />));

    const generateIcon = (type) => {
        switch (type) {
            case 'landmark':
                return <AccountBalanceIcon fontSize="small" />;
            default:
                return <StoreIcon fontSize="small" />;
        }
    };
    
    const fetchRoutes = () => {
        axios.get(
            `${getApiBasePath()}/route/`,
        ).then((response) => {
            setRoutes(response.data);
        }).catch((error) => {
            console.error('Ошибка при получении маршрутов!', error);
        });
    };

    const deleteHandler = (routeId) => {
        axios.delete(
            `${getApiBasePath()}/route/${routeId}`,
        ).then(() => {
            fetchRoutes();
        }).catch((error) => {
            console.error('Ошибка при удалении маршрута!', error);
        });
    };

    useEffect(() => {
        fetchRoutes();
    }, []);

    return (
        <Container component="main" maxWidth="md" sx={{ mt: 5 }}>
            <Button
                onClick={() => navigate(`${getBasePath()}`)}
                type="link"
                variant="outlined"
                size="medium"
                sx={{ mt: 3, mb: 3 }}
            >
                Перейти к управлению достопримечательностями
            </Button>
            {routes && routes.length < 1 && (
                <Typography variant="h5">
                    Маршруты отсутствуют. Добавим?
                </Typography>
            )}
            <Typography variant="body1">
                <Button
                    startIcon={<PersonAddIcon />}
                    variant="contained"
                    size="medium"
                    onClick={() => navigate(`${getBasePath()}/route/new`)}
                    sx={{ mt: 3 }}
                >
                    Добавить маршрут
                </Button>
            </Typography>
            {overlayData && (
                <OverlayModal header={overlayData.name} open={openModal}>
                    <Container component="overlay">
                        <Box sx={{ mt: 2 }}>
                            <YMaps
                                query={{
                                    apikey: process.env.YANDEX_MAPS_API_KEY,
                                }}
                            >
                                <Map
                                    defaultState={{ center: overlayData.points.length > 0? [overlayData.points[0].latitude, overlayData.points[0].longitude] : [46.35839411091701, 48.057499868369696], zoom: 14 }}
                                    options={{ autoFitToViewport: 'always' }}
                                    width="100%"
                                >
                                    <ZoomControl options={{ float: 'right' }} />
                                    {overlayData.points.length > 0 && (
                                        overlayData.points.map((item) => (
                                            <Placemark geometry={[item.latitude, item.longitude]} properties={{ iconCaption: item.name }} />
                                        ))
                                    )}
                                    {overlayData.points.length > 0 && (<Polyline
                                    geometry={overlayData.points.map((item) => [item.latitude, item.longitude])}
                                    options={{
                                        balloonCloseButton: false,
                                        strokeColor: "#000",
                                        strokeWidth: 2,
                                        strokeOpacity: 0.5,
                                    }}
                                />)}
                                </Map>
                            </YMaps>

                        </Box>
                        <Typography variant="h6" padding={1}>
                            Точки маршрута
                        </Typography>
                        <List>
                                {
                                    overlayData.points.map((item) => (
                                        <ListItem key={item.name}>
                                            <ListItemIcon>
                                                {generateIcon(item.type)}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={item.name}
                                                secondary={renderServices(item?.services || [])}
                                            />
                                        </ListItem>
                                    ))
                                }
                        </List>
                        <Stack spacing={2} direction="row" sx={{ mt: 3 }}>
                            <Button variant="contained" color="primary" size="medium" onClick={() => { setOpenModal(false) }}>
                                Закрыть
                            </Button>
                        </Stack>
                    </Container>
                </OverlayModal>
            )}
            {routes && routes.length > 0 && (
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h5">
                        Ваши маршруты:
                    </Typography>
                    <List>
                        {routes.map((item) => (
                            <div key={item.id}>
                                <ListItem
                                    secondaryAction={(
                                        <IconButton edge="end" aria-label="Удалить" onClick={() => deleteHandler(item.id)}>
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                    divider={item.id !== routes[routes.length - 1].id}
                                >
                                    <ListItemIcon>
                                        <DirectionsWalkIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={(
                                            <Typography sx={{ mb: 1 }}>
                                                <Button onClick={() => { setOpenModal(true); setOverlayData(item); }} sx={{ textDecoration: "underline" }}>
                                                    {item.name}
                                                </Button>
                                            </Typography>
                                        )}
                                    />
                                </ListItem>
                            </div>
                        ))}
                    </List>
                </Box>
            )}
        </Container>
    );
}

export default ExpertRoutes;

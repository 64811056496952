import React, { createContext, useState, useContext } from 'react';

const AuthContext = createContext({
    isAuthorized: true,
    setIsAuthorized: () => {},
});

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [isAuthorized, setIsAuthorized] = useState(true);

    return (
        <AuthContext.Provider value={{ isAuthorized, setIsAuthorized }}>
            {children}
        </AuthContext.Provider>
    );
}

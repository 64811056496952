import React, { useState } from 'react';
import { TextField, Button, Alert } from '@mui/material';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import useAxios from '../hooks/useAxios';
import Token from '../utils/Token';
import { usePathHelper } from '../hooks/usePathHelper';

function AuthForm({ parentId = '' }) {
    const location = useLocation();
    const axios = useAxios();
    const { getApiBasePath, getHomePagePath, getMode } = usePathHelper();
    const navigate = useNavigate();

    const [credentials, setCredentials] = useState({
        username: '',
        password: '',
    });

    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        setErrorMessage('');
        axios.post(
            `${getApiBasePath()}/auth/login`,
            qs.stringify(credentials),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            },
        ).then((response) => {
            if (Token.checkTokenExists(getMode())) {
                if (location.pathname.endsWith('/login')) {
                    navigate(getHomePagePath());
                } else {
                    window.location.reload();
                }
            } else {
                setErrorMessage('Ошибка на сервере. Аутентификация в данный момент невозможна.');
            }
        })
            .catch((error) => {
                setErrorMessage('Неправильный логин или пароль.');
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id={`${parentId}-username`}
                label="Email"
                name="username"
                autoComplete="username"
                autoFocus
                value={credentials.email}
                onChange={(e) => setCredentials({ ...credentials, username: e.target.value })}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Пароль"
                type="password"
                id={`${parentId}-password`}
                autoComplete="current-password"
                value={credentials.password}
                onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
            >
                Войти
            </Button>
            {errorMessage && (
                <Alert variant="outlined" severity="error" sx={{ mt: 2 }}>
                    {errorMessage}
                </Alert>
            )}
        </form>
    );
}

export default AuthForm;

import React, { useEffect, useState } from 'react';
import {
    Button, Chip, Container, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography, ButtonGroup
} from '@mui/material';
import { usePathHelper } from '@hooks/usePathHelper';
import useAxios from '@hooks/useAxios';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import DeleteIcon from '@mui/icons-material/Delete';
import DomainIcon from '@mui/icons-material/Domain';
import QrCodeIcon from '@mui/icons-material/QrCode';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';

import QRCode from "qrcode.react";

function Landmarks() {
    const navigate = useNavigate();
    const { getApiBasePath, getBasePath } = usePathHelper();
    const axios = useAxios();

    const [landmarks, setLandmarks] = useState(null);

    const fetchLandmarks = () => {
        axios.get(
            `${getApiBasePath()}/landmark/`,
        ).then((response) => {
            setLandmarks(response.data);
        }).catch((error) => {
            console.error('Ошибка при получении достопримечательностей!', error);
        });
    };

    const deleteHandler = (landmarkId) => {
        axios.delete(
            `${getApiBasePath()}/landmark/${landmarkId}`,
        ).then(() => {
            fetchLandmarks();
        }).catch((error) => {
            console.error('Ошибка при удалении услуги!', error);
        });
    };

    const downloadHandler = (itemId) => {
        const canvas = document.getElementById(`landmark${itemId}`);
        console.log("cnv", canvas);
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = "landmark" + itemId + ".png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const renderCategories = (items) => items.map((el) => (<Chip key={el.id} size="small" label={el.name} variant="outlined" sx={{ mr: 1 }} />));

    useEffect(() => {
        fetchLandmarks();
    }, []);

    return (
        <Container component="main" maxWidth="md" sx={{ mt: 5 }}>
            <Button
                onClick={() => navigate(`${getBasePath()}/routes`)}
                type="link"
                variant="outlined"
                size="medium"
                sx={{ mt: 3 }}
            >
                Перейти к управлению маршрутами
            </Button>
            {landmarks && landmarks.length < 1 && (
                <Typography variant="h5">
                    Локации отсутствуют. Добавим?
                </Typography>
            )}
            <Typography variant="body1">
                <Button
                    startIcon={<DomainAddIcon />}
                    variant="contained"
                    size="medium"
                    onClick={() => navigate(`${getBasePath()}/landmark/new`)}
                    sx={{ mt: 3 }}
                >
                    Добавить достопримечетальность
                </Button>
            </Typography>
            {landmarks && landmarks.length > 0 && (
                <Box sx={{ mt: 3 }}>
                    <Typography variant="h5">
                        Ваши достопримечательности:
                    </Typography>
                    <List>
                        {landmarks.map((item) => (
                            <div key={item.id}>
                                <ListItem
                                    secondaryAction={(
                                        <ButtonGroup>
                                            <IconButton sx={{ marginRight: 1 }} edge="end" aria-label="Сгенерировать QR-код" onClick={() => { downloadHandler(item.id) }}>
                                                <QrCodeIcon fontSize="small" />
                                            </IconButton>,
                                            <IconButton edge="end" aria-label="Удалить" onClick={() => deleteHandler(item.id)}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </ButtonGroup>
                                    )}
                                    divider={item.id !== landmarks[landmarks.length - 1].id}
                                >
                                    <ListItemIcon>
                                        <DomainIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={(
                                            <Typography sx={{ mb: 1 }}>
                                                {item.name}
                                            </Typography>
                                        )}
                                        secondary={renderCategories(item.categories)}
                                    />
                                </ListItem>
                                <div style={{display: 'none'}}>
                                    <QRCode
                                        id={`landmark${item.id}`}
                                        value={JSON.stringify({
                                            id: item.id,
                                            type: 'landmark',
                                        })}
                                        size={400}
                                        level={"H"}
                                        includeMargin={false}
                                    />
                                </div>
                            </div>
                        ))}
                    </List>
                </Box>
            )}
        </Container>
    );
}

export default Landmarks;

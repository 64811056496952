import React, { useEffect, useState } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Button,
    IconButton,
    Tooltip,
    MenuItem,
    Divider,
    Menu, Link,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import Token from '../utils/Token';
import { usePathHelper } from '../hooks/usePathHelper';
import useAxios from '../hooks/useAxios';

function Header({ logoTailing = null }) {
    const {
        getBasePath, getApiBasePath, getMode, getHomePagePath,
    } = usePathHelper();
    const axios = useAxios();
    const navigate = useNavigate();

    const [loggedIn, setLoggedIn] = useState(false);
    const [user, setUser] = useState({
        name: '',
        email: '',
    });

    useEffect(() => {
        setLoggedIn(Token.checkTokenExists(getMode()));
    }, []);

    const handleLogout = () => {
        Token.removeToken(getMode());
        setLoggedIn(false);
        navigate(getHomePagePath());
    };

    const getLogoCopy = (
        <Typography>
            <Typography variant="span" sx={{ color: (logoTailing?.length > 0 ? 'silver' : 'white') }}>
                Пешком.App
                {' '}
                {logoTailing?.length > 0 ? '| ' : ''}
            </Typography>
            {logoTailing}
        </Typography>
    );

    const fetchUserData = () => {
        axios.get(
            `${getApiBasePath()}/auth/info`,
        ).then((response) => {
            setUser(response.data);
        }).catch((error) => {
            console.error('Ошибка при получении данных пользователя!', error);
        });
    };

    useEffect(() => {
        loggedIn && fetchUserData();
    }, [loggedIn]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const formatBonusCopy = (bonus) => {
        const lastDigit = bonus % 10;
        const lastTwoDigits = bonus % 100;

        let suffix;

        if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
            suffix = 'ов';
        } else if (lastDigit === 1) {
            suffix = '';
        } else if (lastDigit >= 2 && lastDigit <= 4) {
            suffix = 'а';
        } else {
            suffix = 'ов';
        }

        return `балл${suffix}`;
    };

    return (
        <AppBar position="sticky">
            <Toolbar>
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                    <Button
                        color="inherit"
                        href={getBasePath()}
                        startIcon={<DirectionsRunIcon style={{ fontSize: 35 }} />}
                    >
                        {getLogoCopy}
                    </Button>
                </Typography>
                {loggedIn ? (
                    <>
                        <Tooltip title={null}>
                            <IconButton
                                onClick={handleClick}
                                sx={{ ml: 2 }}
                                aria-controls={open ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                            >
                                <IconButton>
                                    <AccountCircle sx={{ color: 'white' }} />
                                </IconButton>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <Box sx={{ p: 1, ml: 2, mr: 2 }}>
                                <div>{user?.name}</div>
                                <div>{user?.email}</div>
                                {user.hasOwnProperty("amount") && (<>
                                    <Typography>{user?.amount} {formatBonusCopy(user?.amount)}</Typography>
                                </>)}
                            </Box>
                            <Divider />
                            <MenuItem onClick={handleClose}>
                                <Link onClick={handleLogout}>Выход</Link>
                            </MenuItem>
                        </Menu>
                    </>
                ) : (
                    <>
                        <Button color="inherit" href={`${getBasePath()}/login`}>Войти</Button>
                        <Button color="inherit" href={`${getBasePath()}/register`}>Регистрация</Button>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
}

export default Header;

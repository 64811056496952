import React from 'react';
import {
    Button, Chip, Container, Typography,
} from '@mui/material';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import { useNavigate } from 'react-router-dom';

function HomePage() {
    const navigate = useNavigate();

    return (
        <Container component="main" maxWidth="sm" style={{ marginTop: '8rem' }}>
            <Typography variant="h5" component="h1" gutterBottom>
                Добро пожаловать в
                {' '}
                <Chip color="primary" icon={<DirectionsRunIcon style={{ fontSize: 20 }} />} variant="outlined" label="Peshkom.App" />
            </Typography>
            <Typography variant="body1">
                Это ваш универсальный портал для управления вашим профилем, общения с другими и доступа к эксклюзивному контенту.
            </Typography>
            <Typography variant="body1">
                <Button
                    variant="contained"
                    size="medium"
                    onClick={() => navigate('/category')}
                    sx={{ mt: 3 }}
                >
                    Начать
                </Button>
            </Typography>
        </Container>
    );
}

export default HomePage;

import React, { useEffect, useState } from 'react';
import {
    Typography, Container, Button, Stack, Box
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAxios from '../hooks/useAxios';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NewRoute from './NewRoute';
import ClientRoutes from './ClientRoutes';
import RouteConstructor from './RouteConstructor';

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function SelectRouteMode() {
    const axios = useAxios();
    const navigate = useNavigate();

    const fetchCurrentRoute = () => {
        axios.get(
            '/route/',
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
            .then(() => {
                navigate('/route/');
            })
            .catch((error) => {
                console.error('Ошибка при получинии текущего маршрута!', error);
            });
    };


    useEffect(() => {
        fetchCurrentRoute();
    }, []);

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Container component="main" maxWidth="xl" sx={{ mt: 5 }}>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="scrollable"
                        scrollButtons="auto">
                        <Tab label="Случайный маршрут" {...a11yProps(0)} />
                        <Tab label="Экспертные маршруты" {...a11yProps(1)} />
                        <Tab label="Создать маршрут" {...a11yProps(2)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <NewRoute />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <ClientRoutes />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <RouteConstructor />
                </CustomTabPanel>
            </Box>
        </Container>

    );
}

export default SelectRouteMode;

export const ClientThemeOptions = {
    typography: {
        button: {
            textTransform: 'none',
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& input': {
                        MozAppearance: 'textfield',
                    },
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                    },
                    '& input[type=number]': {
                        MozAppearance: 'textfield',
                    },
                },
            },
        },
    },
};

export const PartnerThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#3f51b5',
        },
        secondary: {
            main: '#f50057',
        },
    },
    typography: {
        button: {
            textTransform: 'none',
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& input': {
                        MozAppearance: 'textfield',
                    },
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                    },
                    '& input[type=number]': {
                        MozAppearance: 'textfield',
                    },
                },
            },
        },
    },
};

export const ExpertThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#f50057',
        },
        secondary: {
            main: '#F73378',
        },
    },
    typography: {
        button: {
            textTransform: 'none',
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& input': {
                        MozAppearance: 'textfield',
                    },
                    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                    },
                    '& input[type=number]': {
                        MozAppearance: 'textfield',
                    },
                },
            },
        },
    },
};

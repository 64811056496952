import React, { useEffect, useState } from 'react';
import {
    Typography, Container, List, ListItem, ListItemIcon, ListItemText, Button, Stack, Chip
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StoreIcon from '@mui/icons-material/Store';
import useAxios from '../hooks/useAxios';
import {
    YMaps, Map, ZoomControl, Placemark, Polyline
} from '@pbe/react-yandex-maps';

function NewRoute() {
    const axios = useAxios();
    const navigate = useNavigate();

    const [points, setPoints] = useState([]);
    const [routeName, setRouteName] = useState(null);

    const [totalCost, setTotalCost] = useState(0);
    const [totalBonus, setTotalBonus] = useState(0);
    
    const renderServices = (items) => items.map((el) => (<Chip key={el.id} size="small" label={el.name} variant="outlined" sx={{ mr: 1 }} />));
    const fetchCurrentRoute = () => {
        axios.get(
            '/route/',
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
            .then(() => {
                navigate('/route/');
            })
            .catch((error) => {
                console.error('Ошибка при получинии текущего маршрута!', error);
            });
    };

    const fetchNewRoute = () => {
        axios.get(
            '/route/generate',
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
            .then((response) => {
                setRouteName(response.data?.name);
                setPoints(response.data?.points || []);
            })
            .catch((error) => {
                console.error('Ошибка при получении данных!', error);
            });
    };

    useEffect(() => {
        // Checking for the current route first
        fetchCurrentRoute();

        // Now generating a new route
        fetchNewRoute();
    }, []);

    const generateIcon = (type) => {
        switch (type) {
            case 'landmark':
                return <AccountBalanceIcon fontSize="small" />;
            default:
                return <StoreIcon fontSize="small" />;
        }
    };

    const handleAccept = () => {
        axios.post(
            '/route/activate',
            {
                points: points.map(
                    (item) => {
                        let services = [];
                        if (item.type === 'partner_point') {
                            services = item?.services || [];
                        }
                        services = services.map((s) => s.id);
                        return ({ id: item.id, type: item.type, services: services });
                    },
                ),
            },
        )
            .then(() => {
                navigate('/route');
            })
            .catch((error) => {
                console.error('Ошибка при сохранении маршрута!', error);
            });
    };

    const handleGenerateNew = () => {
        fetchNewRoute();
    };

    const getTotalCost = () => {
        let sum = 0;
        for (const routePoint of points) {
            if (routePoint.type === 'partner_point') {
                for (const service of routePoint.services) {
                    sum += service.cost;
                }
            }
        }

        return sum;
    };

    const getTotalBonus = () => {
        let sum = 0;
        for (const routePoint of points) {
            if (routePoint.type === 'partner_point') {
                for (const service of routePoint.services) {
                    sum += service.bonus;
                }
            }
        }

        return sum;
    };

    useEffect(() => {
        setTotalCost(getTotalCost());
        setTotalBonus(getTotalBonus());
    }, [points]);

    return (
        <Container component="main" maxWidth="xs" sx={{ mt: 5 }}>
            <Typography component="h1" variant="h5">
                {routeName}
            </Typography>
            <>
                {
                    points.length > 0 && (
                        <>
                        <YMaps
                                query={{
                                    apikey: process.env.YANDEX_MAPS_API_KEY,
                                }}
                            >
                                <Map
                                    defaultState={{ center: points.length > 0 ? [points[0].latitude, points[0].longitude] : [46.35839411091701, 48.057499868369696], zoom: 18, controls: [] }}
                                    options={{ autoFitToViewport: 'always' }}
                                    width="100%"
                                >
                                    <ZoomControl options={{ float: 'right' }} />
                                    {points.length > 0 && (
                                        points.map((item) => (
                                            <Placemark geometry={[item.latitude, item.longitude]} properties={{iconCaption: item.name}}
                                                options={{iconColor: item.is_visited ? "green" : "blue"}}/>
                                        ))
                                    )}
                                    {points.length > 0 && (<Polyline
                                                    geometry={points.map((item) => [item.latitude, item.longitude])}
                                                    options={{
                                                        balloonCloseButton: false,
                                                        strokeColor: "#000",
                                                        strokeWidth: 2,
                                                        strokeOpacity: 0.5,
                                                    }}
                                                />)}
                                </Map>
                            </YMaps>
                            <List>
                                {
                                    points.map((item) => (
                                        <ListItem key={item.name}>
                                            <ListItemIcon>
                                                {generateIcon(item.type)}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={item.name}
                                                secondary={renderServices(item?.services || [])}
                                            />
                                        </ListItem>
                                    ))
                                }
                            </List>
                            <Typography variant="p">
                                Сумма услуг составляет {totalCost} ₽
                            </Typography>
                            <br/>
                            <Typography variant="p">
                                Сумма бонусов составляет {totalBonus}
                            </Typography>

                            <Stack spacing={2} direction="row" sx={{ mt: 3 }}>
                                <Button onClick={handleAccept} variant="contained" size="medium">
                                    Принять
                                </Button>
                                <Button onClick={handleGenerateNew} variant="outlined">Новый вариант</Button>
                            </Stack>
                        </>
                    )
                }
            </>

        </Container>
    );
}

export default NewRoute;

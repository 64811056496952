import React, { useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormGroup, Link, Stack,
    Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAxios from '../hooks/useAxios';

function getTrueKeys(obj) {
    return Object.keys(obj)
        .filter((key) => obj[key] === true)
        .map((key) => parseInt(key, 10));
}

function Category() {
    const navigate = useNavigate();
    const axios = useAxios();

    const [category, setCategory] = useState(null);
    const [formState, setFormState] = useState({});

    const fetchCurrentCategories = () => {
        axios.get(
            '/category/current',
        ).then((response) => {
            if (response.data.length > 0) {
                navigate('/route/select/mode');
            }
        }).catch((error) => {
            console.error('Ошибка при получении сохраненных категорий пользователя!', error);
        });
    };

    const fetchCategories = () => {
        axios.get(
            '/category/',
        ).then((response) => {
            setCategory(response.data);
        }).catch((error) => {
            console.error('Ошибка при получении списка возможных категорий!', error);
        });
    };

    const saveCategories = () => {
        axios.post(
            '/category/',
            getTrueKeys(formState),
        ).then(() => {
            navigate('/route/select/mode');
        }).catch((error) => {
            console.error('Ошибка при сохранении категорий, выбранных пользователем!', error);
            navigate('/route');
        });
    };

    useEffect(() => {
        fetchCurrentCategories();
        fetchCategories();
    }, []);

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setFormState((prevState) => ({
            ...prevState,
            [value]: checked,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        saveCategories();
    };

    return (
        <Container component="main" maxWidth="sm" style={{ marginTop: '8rem' }}>
            <Typography variant="h5" component="h1" gutterBottom>
                Выберите интересующие вас категории
            </Typography>
            <form method="POST" onSubmit={handleSubmit}>
                {
                    category?.length > 0 && (
                        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                            <FormGroup>
                                {
                                    category.map((item) => (
                                        <FormControlLabel
                                            key={item.name}
                                            control={
                                                <Checkbox value={item.id} onChange={handleCheckboxChange} />
                                            }
                                            label={item.name}
                                        />
                                    ))
                                }
                                <Stack spacing={2} direction="row" sx={{ mt: 3 }}>
                                    <Button type="submit" variant="contained" size="medium">
                                        Сохранить
                                    </Button>
                                    <Button onClick={() => navigate('/login')}>Пропустить</Button>
                                </Stack>
                            </FormGroup>
                        </FormControl>
                    )
                }
            </form>
        </Container>
    );
}

export default Category;

import React, { useEffect, useState } from 'react';
import {
    Button, Chip,
    Container,
    List,
    Typography,
} from '@mui/material';
import { usePathHelper } from '@hooks/usePathHelper';
import useAxios from '@hooks/useAxios';
import { useNavigate, useParams } from 'react-router-dom';
import {
    FullscreenControl, Map, Placemark, YMaps, ZoomControl,
} from '@pbe/react-yandex-maps';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import { HttpStatusCode } from 'axios';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ServiceItemBlock from '../Service/ServiceItemBlock';

function ViewPoint() {
    const params = useParams();
    const navigate = useNavigate();
    const axios = useAxios();
    const { getApiBasePath, getBasePath } = usePathHelper();

    const [services, setServices] = useState(null);
    const [data, setData] = useState({
        name: '',
        latitude: '',
        longitude: '',
        categories: [],
    });

    const fetchPoint = () => {
        axios.get(
            `${getApiBasePath()}/point/${params.pointId}`,
        ).then((response) => {
            setData(response.data);
        }).catch((error) => {
            console.error('Ошибка при получении деталей о локации!', error);
        });
    };

    const fetchServices = () => {
        axios.get(
            `${getApiBasePath()}/service/point/${params.pointId}`,
        ).then((response) => {
            setServices(response.data);
        }).catch((error) => {
            if (error.response.status === HttpStatusCode.NotFound) {
                setServices([]);
            }
            console.error('Ошибка при получении услуг локации!', error);
        });
    };

    const renderCategories = (items) => items.map((el) => (<Chip key={el.id} size="small" label={el.name} variant="outlined" sx={{ mr: 1 }} />));

    useEffect(() => {
        fetchPoint();
        fetchServices();
    }, []);

    return (
        <Container component="main" maxWidth="md" sx={{ mt: 5 }}>
            <Typography variant="div">
                <Button
                    startIcon={<ChevronLeftIcon />}
                    onClick={() => navigate(`${getBasePath()}/points`)}
                    sx={{ mb: 3 }}
                >
                    Вернуться к списку локаций
                </Button>
            </Typography>

            <Typography component="h1" variant="h5">
                {data.name}
            </Typography>

            <Typography component="div" sx={{ mt: 2 }}>
                {renderCategories(data.categories)}
            </Typography>

            <Box sx={{ mt: 2 }}>
                <YMaps
                    query={{
                        apikey: process.env.YANDEX_MAPS_API_KEY,
                    }}
                >
                    <Map
                        defaultState={{ center: [data.latitude, data.longitude], zoom: 14 }}
                        options={{ autoFitToViewport: 'always' }}
                        width="100%"
                    >
                        <FullscreenControl />
                        <ZoomControl options={{ float: 'right' }} />
                        <Placemark geometry={[data.latitude, data.longitude]} />
                    </Map>
                </YMaps>
            </Box>

            {services && (
                <>
                    <Typography component="h1" variant="h5" sx={{ mt: 3 }}>
                        Товары и услуги
                    </Typography>

                    {services.length < 1 && (
                        <Typography component="div" sx={{ mt: 2 }}>
                            Пока ничего нет. Добавим?
                        </Typography>
                    )}

                    <Typography variant="body1">
                        <Button
                            startIcon={<AddIcon />}
                            variant="contained"
                            size="medium"
                            onClick={() => navigate(`${getBasePath()}/point/${params.pointId}/service/new`)}
                            sx={{ mt: 2 }}
                        >
                            Добавить
                        </Button>
                    </Typography>
                </>
            )}

            {services?.length > 0 && (
                <Box sx={{ mt: 3 }}>
                    <List>
                        {services.map((item) => (
                            <ServiceItemBlock
                                item={item}
                                onDeleteCallback={fetchServices}
                            />
                        ))}
                    </List>
                </Box>
            )}
        </Container>
    );
}

export default ViewPoint;

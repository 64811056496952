import React, { useEffect, useState } from 'react';
import {
    Alert,
    Autocomplete,
    Button,
    Container,
    FormControl, Stack,
    TextField,
    Typography,
} from '@mui/material';
import { usePathHelper } from '@hooks/usePathHelper';
import useAxios from '@hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import {
    FullscreenControl, Map, Placemark, YMaps, ZoomControl,
} from '@pbe/react-yandex-maps';
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function NewPoint() {
    const navigate = useNavigate();
    const axios = useAxios();
    const { getApiBasePath, getBasePath } = usePathHelper();

    const [categories, setCategories] = useState([]);
    const [placemark, setPlacemark] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isFormDisabled, setFormDisabled] = useState(false);
    const [data, setData] = useState({
        name: '',
        latitude: '',
        longitude: '',
        categories: [],
    });

    const handleSubmit = async (event) => {
        event.preventDefault();

        setFormDisabled(true);
        setErrorMessage('');

        if (!data.latitude) {
            setErrorMessage('Не выбрана точка на карте.');
            setFormDisabled(false);
            return;
        }
        
        axios.post(
            `${getApiBasePath()}/point/`,
            { ...data, categories: (data.categories.map((el) => el.id)) },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
            .then(() => {
                navigate(`${getBasePath()}`);
            })
            .catch((error) => {
                console.error('Ошибка при добавлении локации!', error);
                setErrorMessage('Ошибка при добавлении локации. Попробуйте снова.');
                setFormDisabled(false);
            });
    };

    const fetchCategories = () => {
        axios.get(
            `${getApiBasePath()}/category/`,
        ).then((response) => {
            if (response.data.length > 0) {
                setCategories(response.data);
            }
        }).catch((error) => {
            console.error('Ошибка при получении категорий!', error);
        });
    };

    const handleCategoryChange = (e, v) => {
        setData(
            (prevState) => ({
                ...prevState,
                categories: v,
            }),
        );
    };

    const onMapClick = (e) => {
        const coords = e.get('coords');
        setPlacemark(coords);

        const [lat, long] = coords;
        setData(
            (prevState) => ({
                ...prevState,
                latitude: lat,
                longitude: long,
            }),
        );
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <Container component="main" maxWidth="md" sx={{ mt: 5 }}>
            <Typography variant="div">
                <Button
                    startIcon={<ChevronLeftIcon />}
                    onClick={() => navigate(`${getBasePath()}/points`)}
                    sx={{ mb: 3 }}
                >
                    Вернуться к списку локаций
                </Button>
            </Typography>

            <Typography component="h1" variant="h5">
                Новая локация
            </Typography>

            <form onSubmit={handleSubmit} className="">
                <FormControl disabled={isFormDisabled} fullWidth>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Название"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        value={data.name}
                        disabled={isFormDisabled}
                        onChange={(e) => setData({ ...data, name: e.target.value })}
                    />

                    <Autocomplete
                        multiple
                        limitTags={3}
                        id="categories"
                        options={categories}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Категории"
                            />
                        )}
                        onChange={handleCategoryChange}
                        sx={{ mt: 2 }}
                    />

                    <Box sx={{ mt: 2 }}>
                        <Typography>
                            Выберите точку на карте
                        </Typography>
                        <YMaps
                            query={{
                                apikey: process.env.YANDEX_MAPS_API_KEY,
                            }}
                        >
                            <Map
                                defaultState={{ center: [46.35839411091701, 48.057499868369696], zoom: 14, controls: [] }}
                                onClick={onMapClick}
                                options={{ autoFitToViewport: 'always' }}
                                width="100%"
                            >
                                <FullscreenControl />
                                <ZoomControl options={{ float: 'right' }} />
                                {placemark && (<Placemark geometry={placemark} />)}
                            </Map>
                        </YMaps>
                    </Box>

                    <Stack spacing={2} direction="row" sx={{ mt: 3 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isFormDisabled}
                        >
                            Принять
                        </Button>
                        <Button
                            onClick={() => navigate(`${getBasePath()}`)}
                            type="link"
                        >
                            Отменить
                        </Button>
                    </Stack>
                </FormControl>
                {errorMessage && (
                    <Alert variant="outlined" severity="error" sx={{ mt: 2 }}>
                        {errorMessage}
                    </Alert>
                )}
            </form>
        </Container>
    );
}

export default NewPoint;

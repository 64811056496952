import axios, { HttpStatusCode } from 'axios';
import Token from '@utils/Token';
import { useAuth } from '../context/AuthContext';
import { usePathHelper } from './usePathHelper';

function useAxios() {
    const { isAuthorized, setIsAuthorized } = useAuth();
    const { getMode } = usePathHelper();

    const instance = axios.create({
        baseURL: 'https://peshkom.app/backend/api/v1',
        timeout: 3000,
    });

    instance.interceptors.request.use((config) => {
        const token = Token.getToken(getMode());
        if (token && token.length > 0) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    }, (error) => Promise.reject(error));

    instance.interceptors.response.use((response) => {
        // Handle token storage if auth passed
        if (response.status === HttpStatusCode.Ok && response.data?.access_token?.length > 0) {
            Token.setToken(response.data.access_token, getMode());
            setIsAuthorized(true);
        }

        return response;
    }, (error) => {
        // Handle token removal on any 401
        if (error.response && error.response.status === HttpStatusCode.Unauthorized) {
            Token.removeToken(getMode());
            setIsAuthorized(false);
        }

        return Promise.reject(error);
    });

    return instance;
}

export default useAxios;

import React, { useEffect, useState } from 'react';
import {
    Alert,
    Button,
    Container,
    FormControl, Stack,
    Typography,
    TextField,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Autocomplete,
    Chip
} from '@mui/material';
import { usePathHelper } from '@hooks/usePathHelper';
import useAxios from '@hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import {
    Map, Placemark, YMaps, ZoomControl, Polyline, GeolocationControl
} from '@pbe/react-yandex-maps';
import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DeleteIcon from '@mui/icons-material/Delete';
import { Select, MenuItem, Grid, IconButton } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddIcon from '@mui/icons-material/Add';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { HttpStatusCode } from 'axios';

function RouteConstructor() {
    const navigate = useNavigate();
    const axios = useAxios();
    const { getApiBasePath, getBasePath } = usePathHelper();
    const [routePoints, setRoutePoints] = useState([]);
    const [allPoints, setAllPoints] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [isFormDisabled, setFormDisabled] = useState(false);
    const [newPointId, setNewPointId] = useState(1);

    const [userCoordinate, setUserCoordinate] = useState();
    const [preferredPoints, setPreferredPoints] = useState([]);
    const [userCategories, serUserCategories] = useState([]);
    const [selectedPoint, setSelectedPoint] = useState();

    const [totalCost, setTotalCost] = useState(0);
    const [totalBonus, setTotalBonus] = useState(0);

    const [pointsServices, setPointsServices] = useState({});

    const onPlacemarkClick = (item) => {
        setRoutePoints([...routePoints, {id: newPointId, point: item}]);
        setNewPointId(
            (prevState) => prevState + 1,
        );
    };
    const renderCategories = (items) => items.map((el) => (<Chip key={el.id} size="small" label={el.name} variant="outlined" sx={{ mr: 1 }} />));

    const updatePrefferedPoints = (chosenPoint) => {
        if (!chosenPoint) {
            return;
        }

        let tmpList = [];
        for (const curPoint of allPoints) {
            if (curPoint.id === chosenPoint.id && curPoint.type === chosenPoint.type) {
                continue;
            }
            let l = Math.sqrt(Math.pow((curPoint.latitude - chosenPoint.latitude), 2) + Math.pow((curPoint.longitude - chosenPoint.longitude), 2));
            const userCtgs = new Set(userCategories.map((item) => item.id));
            const pointCtgs = new Set(curPoint.categories.map((item) => item.id));
            let occ = userCtgs.intersection(pointCtgs).size;
            tmpList.push({...curPoint, length: l, occ: occ});
        }
        tmpList.sort((a, b) => a.length - b.length);
        tmpList.sort((a, b) => a.occ > b.occ);
        setPreferredPoints(tmpList.slice(0, 3))
    }

    const fetchPoints = () => {
        axios.get(
            `/point/`,
        ).then((response) => {
            setAllPoints(response.data);
        }).catch((error) => {
            console.error('Ошибка при получении точек!', error);
        });
    };

    const fetchUserCategories = () => {
        axios.get(
            '/category/current',
        ).then((response) => {
            serUserCategories(response.data);
        }).catch((error) => {
            console.error('Ошибка при получении сохраненных категорий пользователя!', error);
        });
    };

    useEffect(() => {
        fetchPoints();
        fetchUserCategories();
    }, []);

    useEffect(() => {
        if (routePoints.length !== 0) {
            updatePrefferedPoints(routePoints[routePoints.length - 1].point);
        } else if (userCoordinate) {
            updatePrefferedPoints({...userCoordinate, id: -1, type: 'landmark'});
        }
    }, [routePoints, userCoordinate]);

    const getTotalCost = () => {
        let sum = 0;
        for (const routePoint of routePoints) {
            if (routePoint?.point?.type === 'partner_point') {
                let tmp = routePoint?.point?.services || [];
                for (const service of tmp) {
                    sum += service.price;
                }  
                
            }
        }

        return sum;
    };

    const getTotalBonus = () => {
        let sum = 0;
        for (const routePoint of routePoints) {
            if (routePoint?.point?.type === 'partner_point') {
                let tmp = routePoint?.point?.services || [];
                for (const service of tmp) {
                    sum += service.bonus;
                }  
                
            }
        }

        return sum;
    };

    const fetchPointServices = (pointId) => {
        axios.get(
            `${getApiBasePath()}/service/point/${pointId}`,
        ).then((response) => {
            setPointsServices(
                (prevState) => {
                    prevState[pointId] = response.data;
                    return prevState;
                }
            )
        }
        ).catch((error) => {
            if (error.response.status === HttpStatusCode.NotFound) {
                return;
            }
            console.error('Ошибка при получении услуг локации!', error);
            return;
        });
    };

    
    useEffect(() => {
        setTotalCost(getTotalCost());
        setTotalBonus(getTotalBonus());
    }, [routePoints]);
    
    useEffect(() => {
        for (const curPoint of allPoints) {
            if (curPoint.type === 'partner_point') {
                fetchPointServices(curPoint.id);
            }
        }
    }, [allPoints]);

    const handleSubmit = async (event) => {
        console.log("routePoints", routePoints);

        event.preventDefault();

        setFormDisabled(true);
        setErrorMessage('');
        
        if (routePoints.length === 0) {
            setErrorMessage('Не выбраны точки для маршрута.');
            setFormDisabled(false);
            return;
        }

        for (let i = 0; i < routePoints.length; i++) {
            if (Object.keys(routePoints[i].point).length === 0) {
                setFormDisabled(false);
                setErrorMessage("Не выбрана локация.");
                return;
            }
        }

        axios.post(
            '/route/activate',
            {
                points: routePoints.map(
                    (item) => {
                        let services = [];
                        if (item.point.type === 'partner_point') {
                            services = item?.point?.services || [];
                        }
                        services = services.map((s) => s.id);
                        return ({ id: item.point.id, type: item.point.type, services: services });
                    },
                ),
            },
        )
            .then(() => {
                navigate('/route');
            })
            .catch((error) => {
                console.error('Ошибка при сохранении маршрута!', error);
            });
    };

    const handleDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return; // dropped outside the list

        const reorderedItems = Array.from(routePoints);
        const [removed] = reorderedItems.splice(source.index, 1);
        reorderedItems.splice(destination.index, 0, removed);

        setRoutePoints(reorderedItems);
    };

    const handleDeletePoint = (pointId) => {
        setRoutePoints(
            (prevState) => prevState.filter((item) => item.id !== pointId)
        );
    };

    const handleAddPoint = () => {
        if (routePoints.every((item) => item.id !== newPointId)) {
            setRoutePoints(
                (prevState) => [...prevState, { id: newPointId, point: {} }]);
            setNewPointId(
                    (prevState) => prevState + 1,
                );
        }
    };

    const handleNewItemChange = (e, value, itemId) => {
        setRoutePoints(
            (prevState) => {
                const index = prevState.map((tmp) => tmp.id).indexOf(itemId);
                if (index !== -1) {
                    prevState[index].point = value;
                }
                return prevState;
            }
        );
        setRoutePoints(
            (prevState) => [...prevState, {id: -1}]
        )
        setRoutePoints(
            (prevState) => prevState.slice(0, -1)
        )
    };

    const handlePointServiceChange = (e, value, itemId) => {
        setRoutePoints(
            (prevState) => {
                const index = prevState.map((tmp) => tmp.id).indexOf(itemId);
                if (index !== -1) {
                    prevState[index].point.services = value;
                }
                return prevState;
            }
        );
        setRoutePoints(
            (prevState) => [...prevState, {id: -1}]
        )
        setRoutePoints(
            (prevState) => prevState.slice(0, -1)
        )
    };

    return (
        <Container component="main" maxWidth="xl" sx={{ mt: 5 }}>
            <Typography component="h1" variant="h5">
                Создайте свой маршрут
            </Typography>

            <form onSubmit={handleSubmit} className="">
                <FormControl disabled={isFormDisabled} fullWidth>
                    <Box sx={{ mt: 2 }}>
                        <Typography>
                            Выберите точки на карте
                        </Typography>
                        <YMaps
                            query={{
                                apikey: process.env.YANDEX_MAPS_API_KEY,
                            }}
                        >
                            <Map
                                defaultState={{ center: [46.35839411091701, 48.057499868369696], zoom: 14, controls: [] }}
                                options={{ autoFitToViewport: 'always' }}
                                width="100%"
                            >
                                <ZoomControl options={{ float: 'right' }} />
                                <GeolocationControl options={{}} onLocationChange={(event) => {
                                    const position = event.get('position');
                                    const [lat, long] = position;
                                    setUserCoordinate({latitude: lat, longitude: long})
                                }}/>
                                {allPoints.length > 0 && (
                                    allPoints.map((item) => (
                                        <Placemark geometry={[item.latitude, item.longitude]} 
                                            properties={{ iconCaption: item.name}}
                                            options={{ iconColor: item.type == 'landmark' ? "green" : "blue" }}
                                            onClick={() => { onPlacemarkClick(item) }}
                                            onMouseEnter={() => {setSelectedPoint((prevState) => item)}}
                                            onMouseLeave={() => {setSelectedPoint((prevState) => null)}}
                                        />
                                    ))
                                )}
                                {routePoints && (<Polyline
                                    geometry={routePoints.filter((item) => item.point?.name != null).map((item) => [item.point?.latitude, item.point?.longitude])}
                                    options={{
                                        balloonCloseButton: false,
                                        strokeColor: "#000",
                                        strokeWidth: 2,
                                        strokeOpacity: 0.5,
                                    }}
                                />)}
                            </Map>
                        </YMaps>
                        <Box sx={{ mt: 3, height: "60px" }}>
                            {selectedPoint && (
                                <>
                                    <Typography variant="h6">
                                        {selectedPoint.name}
                                    </Typography>
                                    <Typography variant="h6">
                                        {renderCategories(selectedPoint.categories)}
                                    </Typography>
                                </>
                            )}
                        </Box>
                        
                        {preferredPoints && preferredPoints.length > 0 && (
                            <Box sx={{ mt: 3 }}>
                                <Typography variant="h5">
                                    Вам могут понравиться эти места
                                </Typography>
                                <List>
                                    {preferredPoints.map((item, index) => (
                                         <div key={index}>
                                            <ListItem
                                                secondaryAction={(
                                                    <IconButton sx={{ marginRight: 1 }} edge="end" aria-label="Сгенерировать QR-код" onClick={() => { onPlacemarkClick(item) }}>
                                                        <AddIcon fontSize="small" />
                                                    </IconButton>
                                                )}
                                                divider={index !== preferredPoints.length}
                                            >
                                                <ListItemText
                                                    primary={(
                                                        <Typography sx={{ mb: 1 }}>
                                                            {item.name}
                                                        </Typography>
                                                    )}
                                                    secondary={renderCategories(item.categories)}
                                                />
                                            </ListItem>
                                         </div>
                                    ))}
                                </List>
                            </Box>
                        )}
                            <DragDropContext onDragEnd={handleDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {routePoints.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                    >
                                                        <Grid container alignItems="center" spacing={2}>
                                                            <Grid item >
                                                                <IconButton {...provided.dragHandleProps}>
                                                                    <DragIndicatorIcon />
                                                                </IconButton>
                                                            </Grid>
                                                            <Grid item auto>
                                                                <FormControl sx={{width: "200px"}}>
                                                                    <Autocomplete
                                                                        disablePortal
                                                                        options={allPoints}
                                                                        getOptionLabel={(option) => option?.name || ""}
                                                                        defaultValue={item.point}
                                                                        sx={{ width: "100%", p: 1 }}
                                                                        renderInput={(params) => <TextField {...params} label="Локация" />}
                                                                        onChange={(e, v) => handleNewItemChange(e, v, item.id)}
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item {...provided.dragHandleProps}>
                                                                <Stack spacing={1} direction="row">
                                                                    <IconButton type="button" edge="end" aria-label="Удалить" onClick={() => handleDeletePoint(item.id)}>
                                                                        <DeleteIcon fontSize="small" />
                                                                    </IconButton>
                                                                    {item.point?.type === 'partner_point' && (
                                                                        <Autocomplete
                                                                            multiple
                                                                            id="services"
                                                                            options={pointsServices[item.point.id]}
                                                                            getOptionLabel={(option) => option?.name || ""}
                                                                            filterOptions={(options) => options}
                                                                            sx={{ width: 300, p: 1 }}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label="Услуги"
                                                                                />
                                                                            )}
                                                                            onChange={(e, v) => handlePointServiceChange(e, v, item.id)}
                                                                        />
                                                                    )}
                                                                </Stack>

                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                            <Button
                                onClick={handleAddPoint}
                                variant="contained"
                                size="medium"
                                sx={{ mt: 3, mb: 3 }}
                            >
                                Добавить локацию
                            </Button>
                    </Box>

                    <Typography variant="p">
                        Сумма услуг составляет {totalCost} ₽
                    </Typography>
                    <Typography variant="p">
                        Сумма бонусов составляет {totalBonus}
                    </Typography>

                    <Stack spacing={2} direction="row" sx={{ mt: 3 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isFormDisabled}
                        >
                            Принять
                        </Button>
                    </Stack>
                </FormControl>
                {errorMessage && (
                    <Alert variant="outlined" severity="error" sx={{ mt: 2 }}>
                        {errorMessage}
                    </Alert>
                )}
            </form>
        </Container>
    );
}

export default RouteConstructor;

import React, { useEffect, useState } from 'react';
import {
    Alert,
    Button,
    Container,
    FormControl, Stack,
    TextField,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Autocomplete,
} from '@mui/material';
import { usePathHelper } from '@hooks/usePathHelper';
import useAxios from '@hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DeleteIcon from '@mui/icons-material/Delete';

import { Select, MenuItem, Grid, IconButton } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


function NewExpertRoute() {
    const navigate = useNavigate();
    const axios = useAxios();
    const { getApiBasePath, getBasePath } = usePathHelper();

    const [errorMessage, setErrorMessage] = useState('');
    const [isFormDisabled, setFormDisabled] = useState(false);
    const [allPoints, setAllPoints] = useState([]);

    const [data, setData] = useState({
        name: '',
        points: [],
    });

    const [points, setPoints] = useState([]);

    const [newPointId, setNewPointId] = useState(1);

    const handleAddPoint = () => {
        setNewPointId(
            (prevState) => prevState + 1,
        );
    };

    const handleNewItemChange = (e, value, itemId) => {
        setPoints(
            (prevState) => {
                const index = prevState.map((tmp) => tmp.id).indexOf(itemId);
                if (index !== -1) {
                    prevState[index].point = value;
                }

                return prevState;
            }
        );
    };

    const handleDeletePoint = (pointId) => {
        setPoints(
            (prevState) => prevState.filter((item) => item.id !== pointId)
        );
    };

    const handleSubmit = async (event) => {        
        event.preventDefault();

        setFormDisabled(true);
        setErrorMessage('');

        if (points.length === 0) {
            setFormDisabled(false);
            setErrorMessage("В маршруте отсутствуют локации.");
            return;
        }
        
        for (let i = 0; i < points.length; i++) {
            if (Object.keys(points[i].point).length === 0) {
                setFormDisabled(false);
                setErrorMessage("Не выбрана локация.");
                return;
            }
        }

        axios.post(
            `${getApiBasePath()}/route/`,
            { ...data, points: points.map((item) => item.point) },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
            .then(() => {
                navigate(`${getBasePath()}/routes`);
            })
            .catch((error) => {
                console.error('Ошибка при регистрации!', error);
                setErrorMessage('Ошибка при добавлении маршрута. Попробуйте снова.');
                setFormDisabled(false);
            });
    };

    const handleDragEnd = (result) => {
        const { source, destination } = result;
        if (!destination) return; // dropped outside the list

        const reorderedItems = Array.from(points);
        const [removed] = reorderedItems.splice(source.index, 1);
        reorderedItems.splice(destination.index, 0, removed);

        setPoints(reorderedItems);
    };

    const fetchPoints = () => {
        axios.get(
            `${getApiBasePath()}/point/`,
        ).then((response) => {
            setAllPoints(response.data);
        }).catch((error) => {
            console.error('Ошибка при получении точек!', error);
        });
    };    
    useEffect(() => {
        fetchPoints();
    }, []);

    useEffect(() => {
        if (newPointId > 1 && points.every((item) => item.id !== newPointId)) {
            setPoints(
                (prevState) => [...prevState, { id: newPointId, point: {} }]);
        }

    }, [newPointId]);

    return (
        <Container component="main" maxWidth="md" sx={{ mt: 5 }}>
            <Typography variant="div">
                <Button
                    startIcon={<ChevronLeftIcon />}
                    onClick={() => navigate(`${getBasePath()}/routes`)}
                    sx={{ mb: 3 }}
                >
                    Вернуться к списку маршрутов
                </Button>
            </Typography>

            <Typography component="h1" variant="h5">
                Новый маршрут
            </Typography>

            <form onSubmit={handleSubmit} className="">
                <FormControl disabled={isFormDisabled} fullWidth>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Название"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        value={data.name}
                        disabled={isFormDisabled}
                        onChange={(e) => setData({ ...data, name: e.target.value })}
                    />
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                >
                                    {points.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                            {(provided) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                >
                                                    <Grid container alignItems="center" spacing={4}>
                                                        <Grid item >
                                                            <IconButton {...provided.dragHandleProps}>
                                                                <DragIndicatorIcon />
                                                            </IconButton>
                                                        </Grid>
                                                        <Grid item xs>
                                                            <FormControl fullWidth>
                                                                <Autocomplete
                                                                    disablePortal
                                                                    options={allPoints}
                                                                    getOptionLabel={(option) => option.name}
                                                                    sx={{ width: 300, p: 1 }}
                                                                    renderInput={(params) => <TextField {...params} label="Локация" />}
                                                                    onChange={(e, v) => handleNewItemChange(e, v, item.id)}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item {...provided.dragHandleProps}>
                                                            <IconButton edge="end" aria-label="Удалить" onClick={() => handleDeletePoint(item.id)}>
                                                                <DeleteIcon fontSize="small" />
                                                            </IconButton>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <Button
                        onClick={handleAddPoint}
                        variant="contained"
                        size="medium"
                        sx={{ mt: 3, mb: 3 }}
                    >
                        Добавить локацию
                    </Button>

                    <Stack spacing={2} direction="row" sx={{ mt: 3 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isFormDisabled}
                        >
                            Принять
                        </Button>
                        <Button
                            onClick={() => navigate(`${getBasePath()}/routes`)}
                            type="link"
                        >
                            Отменить
                        </Button>
                    </Stack>
                </FormControl>
                {errorMessage && (
                    <Alert variant="outlined" severity="error" sx={{ mt: 2 }}>
                        {errorMessage}
                    </Alert>
                )}
            </form>






        </Container>
    );
}

export default NewExpertRoute;

import React, { useEffect, useState } from 'react';
import {
    Button, Container, List, ListItem, ListItemIcon, ListItemText, Typography, Stack, Chip
} from '@mui/material';
import { usePathHelper } from '@hooks/usePathHelper';
import useAxios from '@hooks/useAxios';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import OverlayModal from '../components/OverlayModal';
import {
    Map, Placemark, YMaps, ZoomControl, Polyline
} from '@pbe/react-yandex-maps';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StoreIcon from '@mui/icons-material/Store';

function ClientRoutes() {
    const navigate = useNavigate();
    const { getApiBasePath, getBasePath } = usePathHelper();
    const axios = useAxios();

    const [routes, setRoutes] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [overlayData, setOverlayData] = useState(null);

    const [totalCost, setTotalCost] = useState(0);
    const [totalBonus, setTotalBonus] = useState(0);

    const renderServices = (items) => items.map((el) => (<Chip key={el.id} size="small" label={el.name} variant="outlined" sx={{ mr: 1 }} />));

    const fetchRoutes = () => {
        axios.get(
            `/route/all`,
        ).then((response) => {
            setRoutes(response.data);
        }).catch((error) => {
            console.error('Ошибка при получении маршрутов!', error);
        });
    };

    const handleAccept = () => {
        axios.post(
            '/route/activate',
            {
                points: overlayData?.points.map(
                    (item) => {
                        let services = [];
                        if (item.type === 'partner_point') {
                            services = item?.services || [];
                        }
                        services = services.map((s) => s.id);
                        return ({ id: item.id, type: item.type, services: services });
                    },
                ),
            },
        )
            .then(() => {
                navigate('/route');
            })
            .catch((error) => {
                console.error('Ошибка при сохранении маршрута!', error);
            });
    };

    useEffect(() => {
        fetchRoutes();
    }, []);

    const getTotalCost = () => {
        let sum = 0;
        if (!overlayData) {
            return sum;
        }
        for (const routePoint of overlayData.points) {
            if (routePoint.type === 'partner_point') {
                for (const service of routePoint.services) {
                    sum += service.cost;
                }
            }
        }

        return sum;
    };

    const getTotalBonus = () => {
        let sum = 0;
        if (!overlayData) {
            return sum;
        }
        for (const routePoint of overlayData.points) {
            if (routePoint.type === 'partner_point') {
                for (const service of routePoint.services) {
                    sum += service.bonus;
                }
            }
        }

        return sum;
    };

    useEffect(() => {
        setTotalCost(getTotalCost());
        setTotalBonus(getTotalBonus());
    }, [overlayData]);

    const generateIcon = (type) => {
        switch (type) {
            case 'landmark':
                return <AccountBalanceIcon fontSize="small" />;
            default:
                return <StoreIcon fontSize="small" />;
        }
    };

    return (
        <Container component="main" maxWidth="md" sx={{ mt: 1 }}>
            {overlayData && (
                <OverlayModal header={overlayData.name} open={openModal}>
                    <Container component="overlay">
                        <Box sx={{ mt: 2 }}>
                            <YMaps
                                query={{
                                    apikey: process.env.YANDEX_MAPS_API_KEY,
                                }}
                            >
                                <Map
                                    defaultState={{ center: overlayData.points.length > 0? [overlayData.points[0].latitude, overlayData.points[0].longitude] : [46.35839411091701, 48.057499868369696], zoom: 14 }}
                                    options={{ autoFitToViewport: 'always' }}
                                    width="100%"
                                >
                                    <ZoomControl options={{ float: 'right' }} />
                                    {overlayData.points.length > 0 && (
                                        overlayData.points.map((item) => (
                                            <Placemark geometry={[item.latitude, item.longitude]} properties={{ iconCaption: item.name }} />
                                        ))
                                    )}
                                    {overlayData.points.length > 0 && (<Polyline
                                    geometry={overlayData.points.map((item) => [item.latitude, item.longitude])}
                                    options={{
                                        balloonCloseButton: false,
                                        strokeColor: "#000",
                                        strokeWidth: 2,
                                        strokeOpacity: 0.5,
                                    }}
                                />)}
                                </Map>
                            </YMaps>

                        </Box>
                        <Typography variant="h6" padding={1}>
                            Точки маршрута
                        </Typography>
                        <List>
                                {
                                    overlayData.points.map((item) => (
                                        <ListItem key={item.name}>
                                            <ListItemIcon>
                                                {generateIcon(item.type)}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={item.name}
                                                secondary={renderServices(item?.services || [])}
                                            />
                                        </ListItem>
                                    ))
                                }
                        </List>
                        <Typography variant="p">
                            Сумма услуг составляет {totalCost} ₽
                        </Typography>
                        <br/>
                        <Typography variant="p">
                            Сумма бонусов составляет {totalBonus}
                        </Typography>
                        <Stack spacing={2} direction="row" sx={{ mt: 3 }}>
                            <Button variant="contained" color="primary" size="medium" onClick={() => { setOpenModal(false) }}>
                                Отмена
                            </Button>
                            <Button variant="contained" color="primary" size="medium" onClick={() => { setOpenModal(false); handleAccept(); }}>
                                Пройти
                            </Button>
                        </Stack>
                    </Container>
                </OverlayModal>
            )}
            {routes && routes.length < 1 && (
                <Typography variant="h5">
                    Маршруты отсутствуют.
                </Typography>
            )}
            {routes && routes.length > 0 && (
                <Box sx={{ mt: 1 }}>
                    <Typography variant="h5">
                        Существующие маршруты:
                    </Typography>
                    <List>
                        {routes.map((item) => (
                            <div key={item.id}>
                                <ListItem
                                    divider={item.id !== routes[routes.length - 1].id}
                                >
                                    <ListItemIcon>
                                        <DirectionsWalkIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={(
                                            <Typography sx={{ mb: 1 }}>
                                                <Button onClick={() => { setOpenModal(true); setOverlayData(item); }} sx={{ textDecoration: "underline" }}>
                                                    {item.name}
                                                </Button>
                                            </Typography>
                                        )}
                                    />
                                </ListItem>
                            </div>
                        ))}
                    </List>
                </Box>
            )}
        </Container>
    );
}

export default ClientRoutes;

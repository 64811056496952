import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Register from './pages/Register';
import HomePage from './pages/HomePage';
import Category from './pages/Category';
import CurrentRoute from './pages/CurrentRoute';
import NewRoute from './pages/NewRoute';
import Layout from './layouts/Layout';
import { usePathHelper } from './hooks/usePathHelper';
import Points from './pages/Partner/Point/Points';
import NotFound from './components/NotFound';
import NewPoint from './pages/Partner/Point/NewPoint';
import ViewPoint from './pages/Partner/Point/VewPoint';
import NewService from './pages/Partner/Service/NewService';
import Landmarks from './pages/Expert/Landmark/Landmarks';
import NewLandmark from './pages/Expert/Landmark/NewLandmark';
import QR from './pages/QR';
import ExpertRoutes from './pages/Expert/Route/ExpertRoutes';
import NewExpertRoute from './pages/Expert/Route/NewExpertRoute';
import SelectRouteMode from './pages/SelectRouteMode';
import ClientRoutes from './pages/ClientRoutes';
import RouteConstructor from './pages/RouteConstructor';

function App() {
    const { getBasePath, getMode } = usePathHelper();

    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/category" element={<Category />} />
                <Route path="/route/select/mode" element={<SelectRouteMode />}/>
                <Route path="/route" element={<CurrentRoute />} />
                <Route path="/qr" element={<QR />} />
                <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="/partner" element={<Layout logoTailing="Партнёр" />}>
                <Route path="/partner/" element={<Navigate replace to="/partner/points" />} />
                <Route path="/partner" element={<Navigate replace to="/partner/points" />} />
                <Route path="/partner/login" element={<Login />} />
                <Route path="/partner/register" element={<Register />} />
                <Route path="/partner/points" element={<Points />} />
                <Route path="/partner/point/:pointId" element={<ViewPoint />} />
                <Route path="/partner/point/new" element={<NewPoint />} />
                <Route path="/partner/point/:pointId/service/new" element={<NewService />} />
                <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="/expert" element={<Layout logoTailing="Эксперт" />}>
                <Route path="/expert/" element={<Navigate replace to="/expert/landmarks" />} />
                <Route path="/expert" element={<Navigate replace to="/expert/landmarks" />} />
                <Route path="/expert/login" element={<Login />} />
                <Route path="/expert/register" element={<Register />} />
                <Route path="/expert/landmarks" element={<Landmarks />} />
                <Route path="/expert/landmark/new" element={<NewLandmark />} />
                <Route path="/expert/routes" element={<ExpertRoutes />} />
                <Route path="/expert/route/new" element={<NewExpertRoute />} />
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
}

export default App;

import React, { useState } from 'react';
import {
    Alert,
    Button,
    Container, FormControl, Stack, TextField,
    Typography,
} from '@mui/material';
import { usePathHelper } from '@hooks/usePathHelper';
import useAxios from '@hooks/useAxios';
import { useNavigate, useParams } from 'react-router-dom';
import CurrencyTextField from '@lupus-ai/mui-currency-textfield';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function NewService() {
    const navigate = useNavigate();
    const axios = useAxios();
    const { getApiBasePath, getBasePath } = usePathHelper();
    const params = useParams();

    const [errorMessage, setErrorMessage] = useState('');
    const [isFormDisabled, setFormDisabled] = useState(false);
    const [data, setData] = useState({
        name: '',
        price: 1.00,
        bonus: 1,
    });

    const handleSubmit = async (event) => {
        event.preventDefault();

        setFormDisabled(true);
        setErrorMessage('');
        axios.post(
            `${getApiBasePath()}/service/point/${params.pointId}`,
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
            .then(() => {
                navigate(`${getBasePath()}/point/${params.pointId}`);
            })
            .catch((error) => {
                console.error('Ошибка при добавлении товара или услуги!', error);
                setErrorMessage('Ошибка при добавлении товара или услуги. Попробуйте снова.');
                setFormDisabled(false);
            });
    };

    const convertToNumber = (str) => {
        const cleanedString = str.replace(/ /g, '');

        return parseFloat(cleanedString);
    };

    const handleBonusChange = (e) => {
        let value = e.target.value.replace(/[^0-9]/g, '');
        value = parseInt(e.target.value, 10);

        const minValue = 1;
        const maxValue = 10000;

        if (value === '') {
            value = 1;
        } else if (value < minValue) {
            value = minValue;
        } else if (value > maxValue) {
            value = maxValue;
        }

        setData(
            (prevState) => ({
                ...prevState,
                bonus: value,
            }),
        );
    };

    return (
        <Container component="main" maxWidth="md" sx={{ mt: 5 }}>
            <Typography variant="div">
                <Button
                    startIcon={<ChevronLeftIcon />}
                    onClick={() => navigate(`${getBasePath()}/point/${params.pointId}`)}
                    sx={{ mb: 3 }}
                >
                    Вернуться к локации
                </Button>
            </Typography>

            <Typography component="h1" variant="h5">
                Новый товар или услуга
            </Typography>

            <form onSubmit={handleSubmit} className="">
                <FormControl disabled={isFormDisabled} fullWidth>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="name"
                        label="Название"
                        name="name"
                        autoComplete="name"
                        autoFocus
                        value={data.name}
                        disabled={isFormDisabled}
                        onChange={(e) => setData({ ...data, name: e.target.value })}
                    />

                    <Stack spacing={2} direction="row" sx={{ mt: 3 }}>
                        <CurrencyTextField
                            label="Стоиомость"
                            variant="outlined"
                            value={data.price}
                            currencySymbol="₽"
                            outputFormat="number"
                            decimalCharacter="."
                            digitGroupSeparator=" "
                            required
                            onChange={
                                (e, value) => setData(
                                    (prevState) => ({
                                        ...prevState,
                                        price: convertToNumber(e.target.value),
                                    }),
                                )
                            }
                            minimumValue={1.00}
                            maximumValue={1000000.00}
                        />

                        <TextField
                            id="bonus"
                            label="Бонусы"
                            type="number"
                            value={data.bonus}
                            onChange={handleBonusChange}
                            onBlur={handleBonusChange}
                            required
                            inputProps={{
                                min: 1,
                                max: 10000,
                                step: 1,
                            }}
                            InputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                            }}
                            sx={{ width: '200px' }}
                        />
                    </Stack>

                    <Stack spacing={2} direction="row" sx={{ mt: 3 }}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={isFormDisabled}
                        >
                            Принять
                        </Button>
                        <Button
                            onClick={
                                () => navigate(`${getBasePath()}/point/${params.pointId}`)
                            }
                            type="link"
                        >
                            Отменить
                        </Button>
                    </Stack>
                </FormControl>
                {errorMessage && (
                    <Alert variant="outlined" severity="error" sx={{ mt: 2 }}>
                        {errorMessage}
                    </Alert>
                )}
            </form>
        </Container>
    );
}

export default NewService;

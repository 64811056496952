import * as React from 'react';
import { useLocation } from 'react-router-dom';
import OverlayModal from '../components/OverlayModal';
import { useAuth } from '../context/AuthContext';
import AuthForm from '../forms/AuthForm';

export default function AuthModal() {
    const { isAuthorized, setIsAuthorized } = useAuth();
    const location = useLocation();

    if (location.pathname.endsWith('/login')) {
        return null;
    }

    return (
        <div>
            <OverlayModal
                header="Пожалуйста, войдите в систему"
                open={!isAuthorized}
            >
                <AuthForm parentId="auth-modal" />
            </OverlayModal>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import { Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useAxios from '@hooks/useAxios';

import QrReader from '../components/QrReader';
import { HttpStatusCode } from 'axios';

function QR() {
    const axios = useAxios();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const fetchCurrentRoute = () => {
        axios.get(
            '/route/',
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
            .then(() => {
            })
            .catch((error) => {
                if (error.response.status === HttpStatusCode.NotFound) {
                    navigate('/route/new');
                }
                console.error('Ошибка при получинии текущего маршрута!', error);
            });
    };

    const fetchQR = () => {
        axios.post(
            '/qr/validate',
            data,
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
            .then(() => {
                navigate('/route');
            })
            .catch((error) => {
                console.error('Ошибка при чтении QR-кода!', error);
                setErrorMessage('Ошибка при чтении QR-кода. Попробуйте снова.');
            });
    };

    useEffect(() => {
        fetchCurrentRoute();
    }, []);

    useEffect(() => {
        if (data) {
            fetchQR();
        }
    }, [data]);

    return (
        <Container component="main" maxWidth="sm" style={{ marginTop: '8rem' }}>
            {errorMessage && (
                <Typography>
                    {errorMessage}
                </Typography>
            )}
            <QrReader setData={setData} setErrorMessage={setErrorMessage} />
            <Button
                onClick={() => navigate('/route')}
                type="link"
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
            >
                Завершить чтение QR
            </Button>
        </Container>
    );
}

export default QR;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

// Get the div element with the ID 'root' from your index.html
const rootElement = document.getElementById('root');

// Create a root container instance
const root = ReactDOM.createRoot(rootElement);

// Render the App component within BrowserRouter
root.render(
    //<React.StrictMode>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    //</React.StrictMode>,
);

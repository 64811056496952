import React, { useEffect, useRef, useState } from 'react';

// Styles
import './QrStyles.css';

// Qr Scanner
import QrScanner from 'qr-scanner';

function QrReader({ setData = () => {}, setErrorMessage = () => {} }) {
    // QR States
    const scanner = useRef();
    const videoEl = useRef();
    const qrBoxEl = useRef();
    const [qrOn, setQrOn] = useState(true);

    // Result
    const [scannedResult, setScannedResult] = useState('');

    // Success
    const onScanSuccess = (result) => {
        setScannedResult(result?.data);
        setData(JSON.parse(result?.data));
    };

    const onScanFail = (err) => {
        if (err === "Scanner error: No QR code found") {
            return;
        }
        setErrorMessage('Ошибка при чтении QR-кода. Попробуйте снова.');
    };

    useEffect(() => {
        if (videoEl?.current && !scanner.current) {
            scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
                onDecodeError: onScanFail,
                preferredCamera: 'environment',
                highlightScanRegion: true,
                highlightCodeOutline: true,
                overlay: qrBoxEl?.current || undefined,
            });

            scanner?.current
                ?.start()
                .then(() => setQrOn(true))
                .catch((err) => {
                    if (err) setQrOn(false);
                });
        }

        return () => {
            if (!videoEl?.current) {
                scanner?.current?.stop();
            }
        };
    }, []);

    useEffect(() => {
        if (!qrOn) {
            alert(
                'Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload.',
            );
        }
    }, [qrOn]);

    return (
        <div className="qr-reader">
            <video ref={videoEl} />
            <div ref={qrBoxEl} className="qr-box" />
        </div>
    );
}

export default QrReader;

import React, { useEffect, useState } from 'react';
import {
    Typography, Container, List, ListItem, ListItemIcon, ListItemText, Stack, Button, ListItemSecondaryAction, Rating, Alert,
    Chip
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { HttpStatusCode } from 'axios';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StoreIcon from '@mui/icons-material/Store';
import CheckIcon from '@mui/icons-material/Check';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import CancelIcon from '@mui/icons-material/Cancel';
import useAxios from '../hooks/useAxios';

import {
    YMaps, Map, ZoomControl, Placemark, Polyline
} from '@pbe/react-yandex-maps';

function CurrentRoute() {
    const axios = useAxios();
    const navigate = useNavigate();

    const [points, setPoints] = useState([]);
    const [allVistied, setAllVisited] = useState(false);
    const [rating, setRating] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    const renderServices = (items) => items.map((el) => (<Chip key={el.id} size="small" label={el.name} variant="outlined" sx={{ mr: 1 }} />));

    const fetchCurrentRoute = () => {
        axios.get(
            '/route/',
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        )
            .then((response) => {
                setPoints(response.data?.points);
            })
            .catch((error) => {
                if (error.response.status === HttpStatusCode.NotFound) {
                    navigate('/route/select/mode');
                } else {
                    console.error('Ошибка при получинии данных!', error);
                }
            });
    };

    useEffect(() => {
        fetchCurrentRoute();
    }, []);

    useEffect(() => {
        if (points.length > 0 && points.every(((item) => item.is_visited))) {
            setAllVisited(true);
            setRating(points.map((item) => {return {point_id: item.id, point_type: item.type, rating: 0}}));
        }
    }, [points]);

    const handleRating = () => {
        if (rating.every((item) => item.rating > 0)) {
            setErrorMessage(null);
            axios.post(
                '/review',
                rating,
            )
                .then(() => {
                    navigate('/route/select/mode');
                })
                .catch((error) => {
                    setErrorMessage("Ошибка при отправке оценок. Попробуйте снова")
                });
        } else {
            setErrorMessage("Дайте оценки для всех точек.")
        }
    };

    const handleDecline = () => {
        axios.post(
            '/route/decline',
        )
            .then(() => {
                navigate('/route/select/mode');
            })
            .catch((error) => {
                console.error('Ошибка при отмене маршрута!', error);
            });
    };

    const generateIcon = (type) => {
        switch (type) {
            case 'landmark':
                return <AccountBalanceIcon fontSize="small" />;
            default:
                return <StoreIcon fontSize="small" />;
        }
    };

    const generateVisitIcon = (isVisited) => {
        switch (isVisited) {
            case true:
                return <CheckIcon color="success" fontSize="small" />;
            default:
                return <FollowTheSignsIcon fontSize="small" />;
        }
    };

    const handleQR = () => {
        navigate('/qr');
    };

    return (
        <Container component="main" maxWidth="xs" sx={{ mt: 5 }}>
            <YMaps
                query={{
                    apikey: process.env.YANDEX_MAPS_API_KEY,
                }}
            >
                <Map
                    defaultState={{ center: points.length > 0 ? [points[0].latitude, points[0].longitude] : [46.35839411091701, 48.057499868369696], zoom: 18, controls: [] }}
                    options={{ autoFitToViewport: 'always' }}
                    width="100%"
                >
                    <ZoomControl options={{ float: 'right' }} />
                    {points.length > 0 && (
                        points.map((item) => (
                            <Placemark geometry={[item.latitude, item.longitude]} properties={{iconCaption: item.name}}
                                options={{iconColor: item.is_visited ? "green" : "blue"}}/>
                        ))
                    )}
                    {points.length > 0 && (<Polyline
                                    geometry={points.map((item) => [item.latitude, item.longitude])}
                                    options={{
                                        balloonCloseButton: false,
                                        strokeColor: "#000",
                                        strokeWidth: 2,
                                        strokeOpacity: 0.5,
                                    }}
                                />)}
                </Map>
            </YMaps>
            {!allVistied && (<div>
                {
                    points.length > 0 && (
                        <>
                            <Typography component="h1" variant="h5">
                                Ваш текущий маршрут
                            </Typography>
                            <List>
                                {
                                    points.map((item) => (
                                        <ListItem key={item.name}>
                                            <ListItemIcon>
                                                {generateIcon(item.type)}
                                            </ListItemIcon>
                                            <ListItemText
                                                primary={item.name}
                                                secondary={renderServices(item?.services || [])}
                                            />
                                            <ListItemIcon>
                                                {generateVisitIcon(item.is_visited)}
                                            </ListItemIcon>
                                        </ListItem>
                                    ))
                                }
                            </List>
                            <Stack spacing={2} direction="row" sx={{ mt: 3 }}>
                                <Button
                                    onClick={handleQR}
                                    variant="outlined"
                                    size="primary"
                                    color="secondary"
                                    sx={{ mt: 3 }}
                                >
                                    Прочитать QR
                                </Button>
                                <Button
                                    onClick={handleDecline}
                                    variant="outlined"
                                    size="small"
                                    color="secondary"
                                    sx={{ mt: 3 }}
                                    startIcon={<CancelIcon style={{ fontSize: 15 }} />}
                                >
                                    Отказаться
                                </Button>
                            </Stack>
                        </>
                    )
                }
            </div>)}

            {(allVistied && points.length > 0) && (
                <>
                    <Typography component="h1" variant="h5" sx={{mt: 3}}>
                        Вы успешно закончили маршрут
                    </Typography>
                    <Typography component="h1" variant="h6" sx={{mt: 2}}>
                        Пожалуйста оцените локации
                    </Typography>
                    <List>
                        {
                            points.map((item, index) => (
                                <ListItem key={item.name}>
                                    <ListItemIcon>
                                        {generateIcon(item.type)}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={item.name}
                                    />
                                    <ListItemSecondaryAction>
                                    <Rating
                                        name="simple-controlled"
                                        value={rating[index]?.rating || 0}
                                        onChange={(event, newValue) => {
                                            setRating((prevState) => 
                                                prevState.map((val, idx) => idx === index ? {...val, rating: newValue} : val)
                                        )}}
                                    />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))
                        }
                    </List>
                    {errorMessage && (
                        <Alert variant="outlined" severity="error" sx={{ mt: 2, bt: 2 }}>
                            {errorMessage}
                        </Alert>
                    )}
                    <Stack spacing={2} direction="row" sx={{ mt: 3 }}>
                        <Button
                            onClick={handleRating}
                            variant="outlined"
                            size="primary"
                            color="secondary"
                            sx={{ mt: 3 }}
                        >
                            Оценить
                        </Button>
                    </Stack>
                </>
            )}
        </Container>
    );
}

export default CurrentRoute;

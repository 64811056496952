import React from 'react';
import { Button, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { usePathHelper } from '../hooks/usePathHelper';

function NotFound() {
    const navigate = useNavigate();
    const { getBasePath } = usePathHelper();

    return (
        <Container component="main" maxWidth="xl" style={{ marginTop: '8rem' }}>
            <Typography variant="h1" component="h1" gutterBottom>
                404
            </Typography>
            <Typography variant="body1">
                Страница не существует или была удалена.
            </Typography>
            <Typography variant="body1">
                <Button
                    variant="contained"
                    size="medium"
                    onClick={() => navigate(getBasePath())}
                    sx={{ mt: 3 }}
                >
                    Перейти в начало
                </Button>
            </Typography>
        </Container>
    );
}

export default NotFound;
